body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --mg-color-dark: #000000;
  --mg-color-light: #fcfcfc;
  --mg-color-initial: #ffffff;
  --mg-color-primary: #db1d5c;
  --mg-color-secondary: #302f2f;
  --mg-color-tertiary: #797979;
  --mg-color-quaternary: #a7a7a7;
  --mg-color-quinary: #dadada;
  --mg-control-radius: 0.4rem;
  --mg-input-radius: 0.1rem;
  --mg-color-red: #dc3545;
  --mg-color-orange: #fd7e14;
  --mg-color-blue: #007bff;
  --mg-color-green: #28a745;
  --mg-control-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.15);
}