.app-header .mg-nav--inline > ul {
  margin-bottom: 0;
}

.app-header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.3s ease-in-out;

  z-index: 3;
  background: url(/img/ronds.svg), linear-gradient(0.15turn, #ffffff, #ffffff);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: #fff;
  letter-spacing: normal;
  box-shadow: 0 2px 154px 0 rgba(0, 0, 0, 0.1), 0 0 10px 0 rgba(0, 0, 0, 0.15),
    0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
