.card-container {
  padding: 1rem;
}
.card-small {
  width: 100%;
  height: 10em;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.699);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(51, 51, 51, 0.986);
  text-decoration: none;
  transition: ease-in-out background-color 0.15s;
}

.card-small:hover {
  background-color: var(--mg-color-quinary);
  cursor: pointer;
}

.card-small h3 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.1px;
}
