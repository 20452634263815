.nav-item-sub {
  border-left: 1px solid white;
  margin-left: 1em;
  font-size: 0.9em;
}

.nav-item-active {
  background-color: transparent !important;
  border-left-color: var(--primary) !important;
  border-left-width: 2px;
  border-left-style: solid;
}

.app-sidebar {
  min-width: 250px;
  max-width: 250px;
  position: absolute;
  top: 0;
  color: #fff;
  transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  transform-origin: bottom left;
  background-color: rgb(70, 69, 69);
  min-height: 100vh;
}

.app-sidebar-content {
  margin-top: 10vh;
  width: 250px;
}

@media (min-width: 768px) {
  .app-sidebar {
    margin-left: 0;
  }
  .app-sidebar.active {
    margin-left: 0;
    transform: none;
  }
}

@media (max-width: 768px) {
  .app-sidebar {
    margin-left: -250px;
    transform: rotateY(100deg);
  }

  .app-sidebar.active {
    margin-left: 0;
    transform: none;
  }
}
